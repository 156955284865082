#autoComplete_list {
  display: block;
  position: absolute !important;
  width: 700px !important;
  transition: all 0.3s ease-in-out !important;
  border-radius: 0 0 0.5rem 0.5rem !important;
}

.autoComplete_selected {
  border-left: 2px solid #1890ff !important;
  border-right: 2px solid #1890ff !important;
}

.autoComplete_result:first-child {
  border-radius: unset !important;
}

.autoComplete_result:focus {
  border-left: 2px solid #1890ff !important;
  border-right: 2px solid #1890ff !important;
}

.autoComplete_result:hover {
  border-left: 2px solid #1890ff !important;
  border-right: 2px solid #1890ff !important;
}

.autoComplete_result {
  font-size: 1rem !important;
  padding: 0.6rem 0.3rem !important;
  max-width: none !important;
  color: black !important;
  margin: 0 auto !important;
  /* line-height: 1; */
}

.autoComplete_result:first-child {
  margin-top: 0 !important;
}

.autoComplete_result:last-child {
  border-radius: 0 0 0.5rem 0.5rem !important;
}

.autoComplete_highlighted {
  /* color: #add8e6; */
  color: #1890ff !important;
}

.autoComplete-small-result {
  font-size: 0.8rem !important;
  padding: 0.4rem 0.3rem !important;
}

.ErrorMessage {
  color: #e60013;
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 14px;
  width: 100%;
  transform: translateY(-5px);
  opacity: 1;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

#search:read-only {
  cursor: wait;
}

/* #search:placeholder-shown {
  font-style: italic;
} */

.search-bar-with-element {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.hover-focus-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.summary-view-image-section {
  width: 40%;
  float: left;
  margin: 0 15px 5px 0;
}

.summary-view-inline-image {
  width: 100%;
}

.summary-view-inline-image svg {
  width: 100%;
  transform: scale(0.3);
}

.summary-view-image-caption {
  /* color: black; */
  font-size: 12px;
  text-align: center;
  margin: 2px 5px 0;
  overflow: hidden;
  display: block;
}

.summary-view-image-caption a {
  color: #1890ff;
  font-style: italic;
  text-decoration: none;
}

.summary-view-image-caption a:hover,
.summary-view-image-caption a:focus {
  color: #1890ff;
  text-decoration: underline;
}

@media (max-width: 700px) {
  #autoComplete_list {
    width: calc(100% - 32px) !important;
    /* padding: 0 16px !important; */
  }
}

.Form {
  animation: fade 200ms ease-out;
}

.FormGroup {
  margin: 20px 0 0;
  padding: 0;
  border-style: none;
  background-color: #fff;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 15px;
  border-top: 1px solid #2e677a;
}

.FormRow:first-child {
  border-top: none;
}

.FormRowLabel {
  /* width: 15%; */
  min-width: 60px;
  padding: 11px 0;
  color: #2e677a !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px !important;
  margin-bottom: 0 !important;
  font-weight: 600;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #2e677a;
  /* Hack to hide the default webkit autofill */
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.FormRowInput {
  margin-top: 4px;
  font-size: 16px;
  width: 100%;
  padding: 11px 10px 11px 0;
  color: #2e677a;
  background-color: transparent;
  animation: 1ms void-animation-out;
  border: none;
}

.FormRowInput:focus {
  border: none !important;
  box-shadow: none !important;
  outline-offset: 0;
  outline: none;
}

.FormRowInput::placeholder {
  color: #c0c1c2;
  font-style: normal;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 0 11px 0;
}

.submit-wrapper {
  text-align: center;
}

.btn-submit {
  transition: all 0.3s ease-in-out;
}

.ErrorMessage {
  color: #e60013;
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 14px;
  width: 100%;
  transform: translateY(-5px);
  opacity: 1;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.powered-by-stripe {
  height: 25px;
  max-width: 40%;
  margin: 20px 0px 0;
  /* float: right; */
}

.blog-list-item {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.blog-list-item:hover {
  cursor: pointer;
  transform: translateX(20px);
}

.blog-list-item:hover .blog-list-continue::before {
  width: calc(100% + 12px);
}

.blog-list-continue {
  transition: all 0.5s ease-in-out;
  position: relative;
  color: #1890FF;
  font-weight: 600;
}

.blog-list-continue::before {
  transition: all 0.4s ease-in-out;
  background-color: rgb(81, 93, 238);
  /* background-color: #74CAFF; */
  opacity: 0.1;
  /* bottom: 0px; */
  content: "";
  height: calc(100% + 6px);
  left: -6px;
  position: absolute;
  top: -2px;
  width: 16%;
}

.blog-divider-dots {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.blog-divider-dots span {
  background-color: rgba(8, 8, 8, 1);
  border-radius: 50%;
  display: inline-block;
  width: 5px;
  height: 5px;
}

.blog-divider-dots .with-m {
  margin-right: 40px;
}